import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Footer, Possibility, Features, Header } from './containers';
import { CTA, Brand, Navbar } from './components';
import HomePage from './HomePage';
import Openings from './Openings'
import AboutUss from './AboutUss'
import BlogReal from './BlogReal'



import './App.css';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />}/>
      <Route path="/openings" element={<Openings />}/>
      <Route path="/aboutus" element={<AboutUss />}/>
      <Route path="/blog" element={<BlogReal />}/>

    </Routes>
  </BrowserRouter>

);

export default App;
