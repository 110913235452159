import React from 'react';

import { Footer,Header,About } from './containers';
import { CTA, Brand, Navbar } from './components';

import './App.css';

const AboutUss = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <About/>
      <Footer />
    </div>
  </div>
);

export default AboutUss;
