import React, { useState, useEffect } from "react"
import Article from "../components/article/Article"
import "./blog.css"
import world from "./world.jpg"

const About = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Trigger the animation when the component mounts
    setIsVisible(true)
  }, [])

  return (
    <div
      className={`gpt3__blog section__padding ${
        isVisible ? "slide-up mainxxx" : ""
      }`}
      id="blog">
      <div className="gpt3__blog-heading">
        <h1
          className="gradient__text"
          style={{ textAlign: "left", fontSize: "1.8rem", flex: "1" }}>
          Welcome to Eonify, a pioneering force hailing from the picturesque
          valleys of Kashmir, carving its path in the digital realm with a
          commitment to revolutionize education and beyond.
          <br></br>
          <br></br>
          <br></br>
        </h1>
        <h1
          class="gradient__text removethemargin"
          style={{ textAlign: "left", flex: "1" }}>
          At Eonify, we are not just a company; we are architects of innovation,
          weaving the future of online services with a focus on North India.Our
          pioneering services extend beyond traditional ways, representing the
          initial spark in a wave of innovative solutions that we aspire to
          introduce. As we embark on this odyssey, our sights are set on
          expanding our horizons across diverse sectors. Eonify is not merely a
          name; it's a commitment to endless possibilities, an embodiment of the
          eternal nature of progress. We are proud to be a Kashmir-based
          company, weaving dreams into reality and embracing the rich tapestry
          of our cultural heritage. Join us at Eonify as we transcend
          boundaries, blend tradition with technology, and usher in a new era of
          digital excellence. Together, let's shape a future where innovation
          knows no bounds.
        </h1>
      </div>
      <div
        class="figuresx"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}>
          <h1 style={{ color: "white", fontSize: "5rem" }}>1+</h1>
          <h2 style={{ color: "white", fontWeight: "normal" }}>
            Years of service
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}>
          <h1 style={{ color: "white", fontSize: "5rem" }}>5+</h1>
          <h2 style={{ color: "white", fontWeight: "normal" }}>
            Trusting clients
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}>
          <h1 style={{ color: "white", fontSize: "5rem" }}>500+</h1>
          <h2 style={{ color: "white", fontWeight: "normal" }}>Users</h2>
        </div>
      </div>
    </div>
  )
}

export default About
