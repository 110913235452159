import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Cutting-Edge Technology',
    text: ' Our AI-based software harnesses the power of artificial intelligence, offering cutting-edge solutions that drive innovation and efficiency.',
  },
  {
    title: 'Personalized Experiences',
    text: 'With our software, you can provide personalized and tailored experiences to your customers, enhancing user satisfaction and engagement.',
  },
  {
    title: 'Data-Driven Insights:',
    text: 'Leverage the power of AI to gain valuable data-driven insights that can inform strategic decisions, optimize operations, and drive business growth.',
  },
  {
    title: 'Automation and Efficiency',
    text: ' Streamline your processes and reduce manual work with our AI software, freeing up valuable time and resources to focus on what matters most to your business.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen.</h1>
      {/* <p>Request Early Access to Get Started</p> */}
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
