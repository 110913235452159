import React from 'react';

import { Footer, Possibility, Features, Header } from './containers';
import { CTA, Brand, Navbar } from './components';
import Feature from './components/feature/Feature';


import './App.css';

const HomePage = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Brand />
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3">

    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is AI ?" text="AI, or Artificial Intelligence, refers to the development of computer systems and algorithms that can perform tasks typically requiring human intelligence, such as learning, reasoning, problem-solving, and decision-making." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Chatbots" text="Chatbots assist in real-time conversation and customer support, making interactions efficient. They provide information, answer questions, and can be used for educational purposes, offering personalized learning experiences and delivering relevant content and guidance." />
      <Feature title="Knowledgebase" text="In the context of chatbots, being knowledgeable means having access to a wide range of information and data, allowing the chatbot to provide accurate and relevant answers to user queries. This knowledge base enables chatbots to serve as informative and helpful virtual assistants." />
      <Feature title="Education" text=" AI can enhance the learning process by personalizing instruction, automating administrative tasks, and providing data-driven insights to improve educational outcomes." />
      <Feature title="Healthcare" text=" AI plays an important role in the field of healthcare, where it is used for tasks such as medical image analysis, drug discovery, patient data analysis, and even telemedicine, helping improve diagnostics, treatment, and overall patient care." />
    </div>
    </div>
    {/* <WhatGPT3 /> */}
    <Features />
    <Possibility />
    <CTA />
    {/* <Blog /> */}
    <Footer />
  </div>
);

export default HomePage;
