import React, { useState } from "react"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import { Link } from "react-router-dom"

import logo from "../../logo.svg"
import "./navbar.css"

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu)
  }

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          <p>
            <Link to="/">HOME</Link>
          </p>
          <p>
            <Link to="/openings">CAREERS</Link>
          </p>
          <p>
            <Link to="/aboutus">ABOUT US</Link>
          </p>
          <p>
            <Link to="/blog">BLOG</Link>
          </p>
          {/* <p><a href="#features">Case Studies</a></p>
          <p><a href="#blog">Library</a></p> */}
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        {/* <p>Sign in</p>
        <button type="button">Sign up</button> */}
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine color="#fff" size={27} onClick={handleToggleMenu} />
        ) : (
          <RiMenu3Line color="#fff" size={27} onClick={handleToggleMenu} />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p>
                <Link to="/">Home</Link>
              </p>
              <p>
                <Link to="/openings">Careers</Link>
              </p>
              <p>
                <Link to="/aboutus">About us</Link>
              </p>
              <p>
                <Link to="/blog">Blog</Link>
              </p>
            </div>
            {/* <div className="gpt3__navbar-menu_container-links-sign">
              {/* <p>Sign in</p>
              <button type="button">Sign up</button> 
            </div> */}
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
