import React from "react"
// import people from '../../assets/people.png';
import ai from "../../assets/ai2.png"
import "./header.css"
import { TypeAnimation } from "react-type-animation"
// import Typist from "react-typist"

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">
        <TypeAnimation
          style={{ whiteSpace: "pre-line" }}
          sequence={[
            // Same substring at the start will only be typed out once, initially
            `Let's build something \n amazing with EONIFY`,
            1000,
          ]}
          wrapper="h1"
          speed={60}
          repeat={Infinity}
        />

        {/* <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            "",
            1000,
          ]}
          speed={1}
          repeat={Infinity}
        /> */}
      </h1>
      <p>Unlocking the Future with AI.</p>

      {/* <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div> */}
    </div>

    <div className="gpt3__header-image">
      <img src={ai} alt="AI" className="gpt3__header-image" />
    </div>
  </div>
)

export default Header
