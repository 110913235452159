import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      {/* <p>Request Early Access to Get Started</p> */}
      <h3>Contact us on LinkedIn & start exploring the endless possibilities.</h3>
    </div>
    <div className="gpt3__cta-btn">
      <a href="https://www.linkedin.com/company/eonify-tech/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_companies%3BDSCq64DhQKOMxjyJoxRS4g%3D%3D">
      <button type="button">Follow us</button>
      </a>
    </div>
  </div>
);

export default CTA;
