import React from "react"

import { Footer, Header, Blog } from "./containers"
import { CTA, Brand, Navbar } from "./components"
import Article from "./components/article/Article"
import { img1, img2, img3, img4 } from "./containers/blog/imports"

import "./App.css"
import "./blog.css"

const Openings = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Blog />
      <br></br>
      <br></br>
      <br></br>
    </div>

    <Footer />
  </div>
)

//af

{
  /* <div className="gpt3__blog-container_groupB">
<Article
  imgUrl={img1}
  date="Entry Level"
  text="Full Stack Developer Intern"
/>
<Article
  imgUrl={img2}
  date="Entry Level"
  text="Data Scientist Intern"
/>
<Article
  imgUrl={img3}
  date="Entry Level"
  text="DevOps Engineer Intern"
/>
<Article imgUrl={img4} date="Entry Level" text="QA Engineer Intern" />
</div> */
}

export default Openings
