import React from 'react';
import gpt3Logo from '../../logo.svg';
import './footer.css';
import { Link } from "react-router-dom";


const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Looking to work with us?</h1>
    </div>

    <div className="gpt3__footer-btn">
    <a href="mailto:contact@eonifytech.in" target="_blank" rel="noreferrer">
      <p>Mail Us</p>
    </a>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Eonify Technologies<br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <p>Eonify Team</p>
        <p>contact@eonifytech.in</p>
        <p>Jammu and Kashmir, India</p>
        <p>Srinagar</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p><Link to="/openings">Careers</Link></p>
        <p><Link to="/aboutus">About us</Link></p>
        <p><Link to="/blog">Blog</Link></p>
      </div>

    </div>
      {/* <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div> */}

    <div className="gpt3__footer-copyright">
      <p>@2023 Eonify Tech. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
