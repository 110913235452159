import React from "react"

import { Footer, Header, Blog } from "./containers"
import { CTA, Brand, Navbar } from "./components"
import Article from "./components/article/Article"
import {
  blog01,
  blog02,
  blog03,
  blog04,
  blog05,
} from "./containers/blog/imports"
import Feature from "./components/feature/Feature"
import { Link } from "react-router-dom"

import "./App.css"

const BlogReal = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
    </div>
    <div className="gpt3__whatgpt3 section__margin slide-up" id="wgpt3">
      <div className="gpt3__blog-container_groupB">
        <Link to="https://www.artificial-intelligence.blog/ai-news/the-10-stages-of-robots-becoming-our-new-overlords">
          <Article
            imgUrl={blog02}
            date="February 28, 2023"
            text="Today we examine the transition of robots from friendly, useful, and helpful robots to evil, sinister (and killer) robots with an analysis of the transition from each stage to the next."
          />
        </Link>{" "}
        <Link to="https://www.artificial-intelligence.blog/terminology/reinforcement-learning">
          <Article
            imgUrl={blog03}
            date="April 20, 2023"
            text="Reinforcement learning is a subfield of machine learning, concerned with how software agents can learn to behave in complex, uncertain environments. It relies on feedback from the environment in order to improve the agent's behavior.

"
          />
        </Link>
        <Link to="https://www.artificial-intelligence.blog/ai-news/losing-weight-with-ai">
          <Article
            imgUrl={blog04}
            date="May 30, 2023"
            text="Here we embark on a weight loss plan using skateboarding as the main form of exercise following a plan laid out by GPT-4."
          />
        </Link>{" "}
        <Link to="https://www.artificial-intelligence.blog/ai-news/ai-in-the-modeling-industry">
          <Article
            imgUrl={blog05}
            date="July 12, 2023"
            text="AI has the potential to significantly disrupt the modeling industry in various ways, from automating processes to changing the way models are discovered and represented."
          />
        </Link>
      </div>
    </div>
    <Footer />
  </div>
)

export default BlogReal
