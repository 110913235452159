import React, { useState, useEffect } from "react"
import { blog01, blog02, blog03, blog04, blog05 } from "./imports"
import "./blog.css"

const Blog = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Trigger the animation when the component mounts
    setIsVisible(true)
  }, [])

  return (
    <div
      className={`gpt3__blog section__padding ${
        isVisible ? "slide-up" : ""
      }  xxxx`}
      id="blog">
      <div className="gpt3__about-heading">
        <h1>
          <p style={{}} className="fontsx chrome">
            <u>Current openings:</u>
          </p>
          <p style={{ color: "white " }}>
            No openings available at the moment.
          </p>
          <p style={{ color: "white " }}>
            Follow us on{" "}
            <a href="https://www.linkedin.com/company/eonify-tech/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_companies%3BDSCq64DhQKOMxjyJoxRS4g%3D%3D">
              <span className="gradient__text waviy">LinkedIn</span>
            </a>{" "}
            for the latest updates.
          </p>
        </h1>
      </div>
      <div className="gpt3__blog-container">
        {/* Add your blog content here */}
      </div>
    </div>
  )
}

export default Blog
